import React, { forwardRef, useEffect, useRef } from "react";
import styled from "styled-components";
import NavItem from "../NavItem/NavItem";
import { fadeInUp } from "../../../assets/animations/gsapAnimations";

const NavContent = styled.div`
  @media (max-width: 1200px) {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: var(--main-red);
    overflow: hidden;
    z-index: 98;
  }
`;

const NavUl = styled.ul`
  display: ${({ responsive }) => (responsive ? "none" : "flex")};
  flex-direction: ${({ responsive }) => (responsive ? "column" : "row")};
  justify-content: space-between;
  align-items: ${({ responsive }) => (responsive ? "center" : null)};
  list-style: none;
  flex-basis: ${({ responsive }) => (responsive ? "70%" : "85%")};

  @media (max-width: 1200px) {
    display: ${({ responsive }) => (responsive ? "flex" : "none")};
  }
`;

const NavList = forwardRef(({ state, linkClicked, responsive }, ref) => {
  let line1 = useRef(null);
  let line2 = useRef(null);
  let line3 = useRef(null);
  let line4 = useRef(null);
  let line5 = useRef(null);
  let line6 = useRef(null);
  let line7 = useRef(null);
  let line8 = useRef(null);
  let line9 = useRef(null);

  useEffect(() => {
    if (state.clicked) {
      fadeInUp(line1, line2, line3, line4, line5, line6, line7, line8, line9);
    }
  }, [state]);

  return (
    <NavUl responsive={responsive} ref={ref}>
      <NavItem
        path="/"
        text="Strona Główna"
        linkClicked={linkClicked}
        ref={el => (line1 = el)}
        key="1"
      />
      <NavItem
        path="/o-nas/"
        text="O nas"
        linkClicked={linkClicked}
        ref={el2 => (line2 = el2)}
        key="2"
      />
      <NavItem
        path="/dla-domu-firm/"
        text="Oferta dla domu i firm"
        linkClicked={linkClicked}
        ref={el3 => (line3 = el3)}
        key="3"
      />
      <NavItem
        path="/telewizja/"
        text="Telewizja"
        linkClicked={linkClicked}
        ref={el4 => (line4 = el4)}
        key="4"
      />
      <NavItem
        path="/wspolpraca/"
        text="Współpraca"
        linkClicked={linkClicked}
        ref={el5 => (line5 = el5)}
        key="5"
      />
      <NavItem
        path="/pakiety/"
        text="Pakiety"
        linkClicked={linkClicked}
        ref={el6 => (line6 = el6)}
        key="6"
      />
      <NavItem
        path="/zasieg/"
        text="Zasięg"
        linkClicked={linkClicked}
        ref={el7 => (line7 = el7)}
        key="7"
      />
      <NavItem
        path="#kontakt/"
        text="Kontakt"
        linkClicked={linkClicked}
        ref={el8 => (line8 = el8)}
        key="8"
      />
      <NavItem
        path="/panelklienta/"
        text="Panel klienta"
        linkClicked={linkClicked}
        ref={el9 => (line9 = el9)}
        key="9"
      />
    </NavUl>
  );
});

export default NavList;
