exports.components = {
  "component---src-layouts-place-js": () => import("./../../../src/layouts/place.js" /* webpackChunkName: "component---src-layouts-place-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dla-domu-firm-js": () => import("./../../../src/pages/dla-domu-firm.js" /* webpackChunkName: "component---src-pages-dla-domu-firm-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-pakiety-js": () => import("./../../../src/pages/pakiety.js" /* webpackChunkName: "component---src-pages-pakiety-js" */),
  "component---src-pages-panelklienta-js": () => import("./../../../src/pages/panelklienta.js" /* webpackChunkName: "component---src-pages-panelklienta-js" */),
  "component---src-pages-telewizja-js": () => import("./../../../src/pages/telewizja.js" /* webpackChunkName: "component---src-pages-telewizja-js" */),
  "component---src-pages-wspolpraca-js": () => import("./../../../src/pages/wspolpraca.js" /* webpackChunkName: "component---src-pages-wspolpraca-js" */),
  "component---src-pages-zasieg-js": () => import("./../../../src/pages/zasieg.js" /* webpackChunkName: "component---src-pages-zasieg-js" */)
}

