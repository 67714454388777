/* eslint-disable no-lone-blocks */
/* eslint-disable import/no-anonymous-default-export */
import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { TweenMax } from "gsap";

import Navigation from "../components/Navigation/Navigation";
import GlobalStyles from "../assets/styles/globalStyles";
// import CookieConsent from "react-cookie-consent";

import Contact from "../components/Contact/Contact";
import Arrow from "../components/Arrow/Arrow";

{
  /* <CookieConsent
          location="bottom"
          buttonText="zaakceptuj"
          declineButtonText="odrzuć"
          cookieName="gatsby-gdpr-google-analytics"
          style={{
            background: "#242424",
            fontSize: "16px",
            fontWeight: "regular",
          }}
          buttonStyle={{
            color: "#fff",
            backgroundColor: "#fb2525",
            fontSize: "16px",
            padding: "10px 20px",
          }}
          declineButtonStyle={{
            color: "#dedede",
            backgroundColor: "#46464f",
            fontSize: "16px",
            padding: "10px 20px",
          }}
          enableDeclineButton={true}
          expires={150}
          debug={true}
        >
          Ta strona uzywa plików cookie do poprawnego działania
        </CookieConsent> */
}

const MainContent = styled.main`
  /* margin-top: var(--nav-height); */
  position: relative;
`;

const AlternativeLayout = ({ pageContext, children }) => {
  let app = useRef(null);

  return (
    <>
      <GlobalStyles />
      <Navigation />
      <MainContent ref={el => (app = el)}>
        {children}
        <Contact zasieg={true} />
        <Arrow />
      </MainContent>
    </>
  );
};

const TvLayout = ({ pageContext, children }) => {
  let app = useRef(null);
  return (
    <>
      <GlobalStyles />
      <Navigation />
      <MainContent ref={el => (app = el)}>
        {children}
        <Contact tv={true} />
        <Arrow />
      </MainContent>
    </>
  );
};

const MainLayout = ({ children }) => {
  let app = useRef(null);

  // if (typeof window !== "undefined") {
  //   // Check if there are any differences between the server and the client render
  //   if (document.getElementById("root").innerHTML !== "") {
  //     console.error(
  //       "Hydration failed because the initial UI does not match what was rendered on the server"
  //     );
  //   }
  // }

  useEffect(() => {
    TweenMax.to(app, 0, { css: { visibility: "visible" } });
  });

  return (
    <>
      <GlobalStyles />
      <Navigation />
      <MainContent ref={el => (app = el)}>
        {children}
        <Contact />
        <Arrow />
      </MainContent>
    </>
  );
};

export default ({ children, pageContext }) => {
  if (pageContext.layout === "zasieg") {
    return <AlternativeLayout>{children}</AlternativeLayout>;
  }
  if (pageContext.layout === "tv") {
    return <TvLayout>{children}</TvLayout>;
  }
  return <MainLayout>{children}</MainLayout>;
};
